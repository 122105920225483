import React from "react"
import { graphql } from "gatsby"

import Page from "~/blocks/Page/Page.js"
import SEO from "~/blocks/Seo/Seo.js"
import Heading from "~/blocks/Heading/Heading.js"
import Subsidiary from "~/blocks/Subsidiary/Subsidiary.js"

import "~/helper-blocks/Content/Content.scss"
import "~/helper-blocks/Separator/Separator.scss"
import "~/helper-blocks/Utilities/Utilities.scss"

/*
	ADDRESSES PAGE
*/
const OndeEstamosPage = ({data}) => {
	const subsidiaries = data.allSubsidiariesJson.nodes;
	const states = data.allStatesJson.nodes;

	return (
		<Page
			title="Onde Estamos"
			centered={true}
		>
			<SEO
				title="Onde Estamos"
				description="Encontre nossa filial mais próxima - CantuStore"
				pathname="/onde-estamos"
			/>

			<div className="content">
				<h1>Onde Estamos</h1>
				<p>A gente quer estar em todos os cantos do Brasil, sempre para abrir caminhos onde muitos enxergam apenas pneus. Já estamos em todos as regiões do país e nossa meta e crescer ainda mais. Dá uma olhadinha onde estão as nossas filiais e como você pode conversar com a gente.</p>
			</div>
			
			<hr />
			
			<div className="grid grid--gap--2">
				{ states.map( state => {
					return(
						<div className="grid__item grid grid--gap--1" key={state.id}>
							<Heading
								level={2}
								className="h2"
							>{ state.name }</Heading>

							<div className="grid grid--2-columns-md-up grid--gap--1">
								{ subsidiaries.map( subsidiary => {
									if (subsidiary.state !== state.name) {
										return false;
									}
									
									return (
										<Subsidiary
											level={3}
											subsidiary={subsidiary}
											key={subsidiary.id}
										/>
									)
								}) }
							</div>
						</div>
					)
				}) }
			</div>
		</Page>
	)
}

export default OndeEstamosPage;

export const query = graphql`
	query SubsidiariesQuery {
		allSubsidiariesJson {
			nodes {
				cep
				city
				address
				state
                telephone
                type
                id
			}
		}
		allStatesJson(sort: {
			fields: [name],
			order: ASC
		}) {
			nodes {
				name
				id
			}
		}
	}
`
