import React from 'react';

import Heading from "~/blocks/Heading/Heading.js"
import Icon from "~/blocks/Icon/Icon.js"

import './Subsidiary.scss';

/*
	SUBSIDIARY: Single Subsidiary Entry
*/
const Subsidiary = (props) => {
	const { subsidiary, level } = props;

	let telephones = "";

	var symbol = subsidiary.type == 'container' ? 'container' : subsidiary.type == 'standard' ? 'map-pin' : 'cd'

	var title = subsidiary.type == 'container' ? 'Loja Container' : subsidiary.type == 'standard' ? 'Filial' : 'CD'

	if ( subsidiary.telephone != null && subsidiary.telephone.length > 0 ) {
		telephones = subsidiary.telephone.map(telephone =>
			<a href={'tel:' + telephone} key={telephone} className="subsidiary__telephone">
				{telephone}
			</a>
		);
	}

	return (
		<div
			className="subsidiary"
			{...props}
		>
			<Icon
				title={title}
				aria-label={title}
				symbol={symbol}
				mix="subsidiary__icon"
			/>

			<Heading
				level={level}
				className="subsidiary__city"
			>
				{ subsidiary.city }
			</Heading>

			<p className="subsidiary__address">
				{ subsidiary.address }
			</p>
			<p className="subsidiary__cep">
				{ subsidiary.cep }
			</p>
			<div className="subsidiary__telephones">
				{ telephones }
			</div>
		</div>
	);
};
export default Subsidiary;